import React from 'react';
import i1 from '../../assets/ss1.jpg'
import i2 from '../../assets/ss2.jpg'
import i3 from '../../assets/ss3.jpg'
import i4 from '../../assets/ss4.jpg'
import i5 from '../../assets/ss5.jpg'
import staff from '../../assets/c3.jpg'
import { FiLayout } from "react-icons/fi";
import { SiXdadevelopers } from "react-icons/si";
import { SiManageiq } from "react-icons/si";
import { FaDeviantart } from "react-icons/fa";
import { MdEngineering } from "react-icons/md";

const ServicesSection = () => {
  return (
    <section className="py-16 text-center ">
      <div className="container mx-auto">
        {/* Section Title */}
        <div className="mb-12">
          <h2 className="text-4xl font-semibold">
            <span className="text-[--bg-color]">Services</span> we provide
          </h2>
          <p className="text-gray-500 mt-4">
            You will find yourself working in a true partnership that results in an incredible experience, and an end product that is the best.
          </p>
        </div>

        {/* Services Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-[80vw] m-auto">

          <div className="relative">
            <img
              src={i1}
              alt="Petroleum Refinery"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="relative bottom-12 bg-[--bg-color] text-white text-center p-4 mx-6 pb-8 h-64">
              <div className="flex justify-center mb-4">
                <div className="bg-gray-100 p-4 rounded-full absolute -top-8">
                  <SiXdadevelopers className=' text-black' size={40} />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <i className="icon-class-for-mechanical-engineering text-3xl"></i>
              </div>
              <h3 className="text-xl font-semibold">Software Development</h3>
              <p className="text-sm mt-2">
                We craft bespoke software solutions that are robust, scalable, and tailored to meet the specific needs of your business, ensuring high performance and user satisfaction.              </p>
            </div>
          </div>

          <div className="relative">
            <img
              src={i2}
              alt="Petroleum Refinery"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="relative bottom-12 bg-[--bg-color] text-white text-center p-4 mx-6 pb-8 h-64">
              <div className="flex justify-center mb-4">
                <div className="bg-gray-100 p-4 rounded-full absolute -top-8">
                  <SiManageiq className=' text-black' size={40} />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <i className="icon-class-for-mechanical-engineering text-3xl"></i>
              </div>
              <h3 className="text-xl font-semibold">Web and App Development</h3>
              <p className="text-sm mt-2">
                Our expertise in web and mobile app development delivers high-quality, cross-platform applications that are optimized for performance, accessibility, and user engagement.
              </p>
            </div>
          </div>


          <div className="relative">
            <img
              src={i3}
              alt="Petroleum Refinery"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="relative bottom-12 bg-[--bg-color] text-white text-center p-4 mx-6 pb-8 h-64">
              <div className="flex justify-center mb-4">
                <div className="bg-gray-100 p-4 rounded-full absolute -top-8">
                  <FiLayout className=' text-black' size={40} />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <i className="icon-class-for-mechanical-engineering text-3xl"></i>
              </div>
              <h3 className="text-xl font-semibold">Telecom</h3>
              <p className="text-sm mt-2">
                We offer specialized services in telecom, providing cutting-edge solutions that enhance connectivity, improve operational efficiency, and drive business growth in the telecommunications sector.
              </p>
            </div>
          </div>

          <div className="relative">
            <img
              src={i4}
              alt="Petroleum Refinery"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="relative bottom-12 bg-[--bg-color] text-white text-center p-4 mx-6 pb-8 h-64">
              <div className="flex justify-center mb-4">
                <div className="bg-gray-100 p-4 rounded-full absolute -top-8">
                  <SiXdadevelopers className=' text-black' size={40} />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <i className="icon-class-for-mechanical-engineering text-3xl"></i>
              </div>
              <h3 className="text-xl font-semibold">Cloud and Devops</h3>
              <p className="text-sm mt-2">
                Our cloud and DevOps services streamline your development processes, enabling continuous integration, automated deployment, and scalable infrastructure management for faster and more reliable software delivery.
              </p>
            </div>
          </div>

          <div className="relative">
            <img
              src={i5}
              alt="Petroleum Refinery"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="relative bottom-12 bg-[--bg-color] text-white text-center p-4 mx-6 pb-8 h-64">
              <div className="flex justify-center mb-4">
                <div className="bg-gray-100 p-4 rounded-full absolute -top-8">
                  <MdEngineering className=' text-black' size={40} />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <i className="icon-class-for-mechanical-engineering text-3xl"></i>
              </div>
              <h3 className="text-xl font-semibold">Product Engineering</h3>
              <p className="text-sm mt-2">
                We deliver end-to-end product engineering services, from conceptualization to deployment, ensuring your products are innovative, scalable, and aligned with market demands.              </p>
            </div>
          </div>


          <div className="relative">
            <img
              src={staff}
              alt="Petroleum Refinery"
              className="w-full h-56 object-cover rounded-lg"
            />
            <div className="relative bottom-12 bg-[--bg-color] text-white text-center p-4 mx-6 pb-8 h-64">
              <div className="flex justify-center mb-4">
                <div className="bg-gray-100 p-4 rounded-full absolute -top-8">
                  <FaDeviantart className=' text-black' size={40} />
                </div>
              </div>
              <div className="flex justify-center mb-2">
                <i className="icon-class-for-mechanical-engineering text-3xl"></i>
              </div>
              <h3 className="text-xl font-semibold">Staff Augmentation</h3>
              <p className="text-sm mt-2">
                Our staff augmentation services provide you with skilled professionals who seamlessly integrate with your team, helping you meet project demands and achieve your business objectives efficiently.
              </p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
