import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo.jpeg'
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaSquareTwitter } from "react-icons/fa6";

const Footer = () => {
    return (
        <footer class="bg-[--bg-color] shadow dark:bg-gray-900 pt-32 px-8">
            <div class="w-full max-w-screen-xl mx-auto md:py-8">
                <div class="sm:flex sm:items-center sm:justify-between">
                    <Link href="/" class="flex flex-wrap justify-center items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                        <img src={logo} class="h-8 w-8 rounded-lg " alt="Logo" />
                        <span class="self-center text-sm text-white font-semibold whitespace-nowrap dark:text-white">Jinrai Technologies Private Limited</span>
                    </Link>

                    <ul class="md:flex md:flex-wrap md:items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                        <li className='text-center'>
                            <a onClick={() => window.location.href = '/about'} class="hover:underline me-4 md:me-6 text-white text-center cursor-pointer">About</a>
                        </li>
                        <li className='text-center'>
                            <a href='#' class="hover:underline me-4 md:me-6 text-white text-center cursor-pointer">Privacy Policy</a>
                        </li>
                        <li className='md:pt-0 mt-6'>
                            <p class="text-white text-center">Contact with Us</p>
                            <p class=" text-white text-xl flex flex-row md:justify-around justify-center md:gap-0 gap-4 mt-1">
                                <a href='https://linkedin.com/company/jinraitech' target='_blank'> <FaLinkedin className='cursor-pointer' /></a>
                                <a href='https://facebook.com/jinraitech' target='_blank'> <FaFacebook className='cursor-pointer' /></a>
                                <a href='https://twitter.com/Jinraitech' target='_blank'> <FaSquareTwitter className='cursor-pointer' /></a>
                            </p>
                        </li>
                    </ul>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
                <div class="md:flex md:justify-center text-sm text-white text-center dark:text-gray-400">
                    <div className='pr-1'>© 2024</div>
                    <span class="underline">Jinrai Technologies Private Limited.</span>
                    <div className='pl-1'> All Rights Reserved.</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer

