import React from 'react';
import { FaPhoneAlt } from "react-icons/fa";
import { FaHome, FaEnvelope } from 'react-icons/fa';

const ContactSection = () => {
  return (
    <div>
      {/* Contact Options Section */}
      <div className="container mx-auto w-[80vw] py-12 mt-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Visit Us Card */}
          <div className="text-center p-6 bg-white shadow-lg rounded-lg">
            <div className="text-[--bg-color] flex justify-center text-4xl mb-4">
              <FaHome />
            </div>
            <h2 className="text-xl font-bold mb-2">VISIT US</h2>
            <p className="text-gray-600">
               S-08, Building no. A-60, Sector-2, Noida, UP, India - 201301
            </p>
          </div>

          {/* Call Us Card */}
          <div className="text-center p-6 bg-white shadow-lg rounded-lg">
            <div className="text-[--bg-color]  flex justify-center text-4xl mb-4">
            <FaPhoneAlt />

            </div>
            <h2 className="text-xl font-bold mb-2">CALL US</h2>
            <p className="text-gray-600">
               +91-120-4075129
            </p>
          </div>

          {/* Contact Us Card */}
          <div className="text-center p-6 bg-white shadow-lg rounded-lg">
            <div className="text-[--bg-color] flex justify-center text-4xl mb-4">
              <FaEnvelope />
            </div>
            <h2 className="text-xl font-bold mb-2">CONTACT US</h2>
            <p className="text-gray-600">
              contact@jinraitech.com
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
