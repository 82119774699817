import React from 'react';

const GetQuoteSection = () => {
  return (
    <section className="py-20 bg-[--bg-color] text-white text-center w-[90vw] m-auto">
      <div className="container mx-auto">
        <h3 className="text-sm font-medium mb-2 items-center">
          <span className="inline-block border-t-2 border-white w-10 mx-auto "></span>
          Get A Quote
          <span className="inline-block border-t-2 border-white w-10 mx-auto"></span>
        </h3>
        <h2 className="text-4xl font-semibold my-4 md:mx-44 leading-normal">
          Feel Any Project For Business Consulting Get Started Us
        </h2>
        <button onClick={() => window.location.href = "/contact"} className="mt-8 px-8 py-4 bg-black text-white font-medium rounded-full">
          Get Started
        </button>
      </div>
    </section>
  );
};

export default GetQuoteSection;
