import React from 'react';
import img from '../../assets/coding (2).png';
import img2 from '../../assets/project (1).png';
import img3 from '../../assets/technical-support.png';
import img4 from '../../assets/system-integration.png';

const ProjectProcess = () => {
  return (
    <section className="text-center py-16 pb-20">
      <div className="container mx-auto">
        {/* Title */}
        <div className="mb-8">
          <h2 className="my-2 text-3xl font-semibold mb-9">
            Our Approch
          </h2>
        </div>

        {/* Services */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 w-[80vw] mx-auto justify-center text-center mt-16">
          <div className="flex flex-col items-center">
            <img
              src={img}
              alt="Petroleum Refinery"
              className="w-20 h-20 object-cover rounded-lg"
            />
            <h3 className="text-xl font-semibold mt-4">Strategy</h3>
            <p className="text-gray-500 mt-2">
              We create a detailed plan to align the software solution with business goals and user needs.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={img2}
              alt="Petroleum Refinery"
              className="w-20 h-20 object-cover rounded-lg"
            />
            <h3 className="text-xl font-semibold mt-4">Design</h3>
            <p className="text-gray-500 mt-2">
              We design intuitive, user-centric interfaces that enhance usability and engagement.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={img3}
              alt="Petroleum Refinery"
              className="w-20 h-20 object-cover rounded-lg"
            />
            <h3 className="text-xl font-semibold mt-4">Development</h3>
            <p className="text-gray-500 mt-2">
              Our team builds scalable, secure, and high-performing software using best practices.

            </p>
          </div>
          <div className="flex flex-col items-center">
            <img
              src={img4}
              alt="Petroleum Refinery"
              className="w-20 h-20 object-cover rounded-lg"
            />
            <h3 className="text-xl font-semibold mt-4">Quality Check</h3>
            <p className="text-gray-500 mt-2">
              We rigorously test the software to ensure it is reliable, bug-free, and ready for deployment.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectProcess;
