import React from 'react';
import H1 from '../assets/H4.jpg';
import { motion } from "framer-motion"
import { useNavigate } from 'react-router-dom';

const HomeDetails = () => {
    const navigate = useNavigate()
    return (
        <section className="relative h-screen flex items-center justify-center">
            {/* Background Image */}
            <img
                src={H1}
                alt="Agency Background"
                className="absolute inset-0 object-cover w-full h-full"
            />

            {/* Black Overlay */}
            <div className="absolute inset-0 bg-[#852D2D] opacity-35"></div>

            {/* Content */}
            <div className="relative z-10 text-center text-white">
                <motion.h1
                    initial={{ x: 50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                        delay: 0.2,
                        x: { type: "spring", stiffness: 60 },
                        opacity: { duration: 1 },
                        ease: "easeIn",
                        duration: 1
                    }}

                    className="md:text-7xl sm:text-4xl text-2xl mx-auto font-bold leading-tight mb-5 md:w-[800px] w-[80%]">
                    Leading Your Project With Expert
                </motion.h1>
                <motion.p
                    initial={{ x: -50, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{
                        delay: 0.4,
                        x: { type: "spring", stiffness: 60 },
                        opacity: { duration: 1 },
                        ease: "easeIn",
                        duration: 1
                    }}
                    className="text-sm font-normal mb-8 md:w-[800px] w-[90%] mx-auto">Dynamic range of services designed to empower organizations to achieve their business objectives with the knowledge and strategies needed to thrive in today’s ever-evolving Technology world.</motion.p>
                <motion.div
                    initial={{ y: 100, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{
                        delay: 0.6,
                        y: { type: "spring", stiffness: 60 },
                        opacity: { duration: 0.7 },
                        ease: "easeIn",
                        duration: 1
                    }}

                    className="sm:flex sm:justify-center md:space-x-4 w-full">
                    <button onClick={() => navigate('/portfolio')} className="px-6 py-3 font-semibold bg-[--bg-color] rounded hover:bg-[#ff4b4c]">
                        Learn more
                    </button>
                    <button onClick={() => navigate('/contact')} className="px-6 py-3 font-semibold bg-white text-gray-800 rounded hover:bg-gray-100">
                        Contact us
                    </button>
                </motion.div>
            </div>
        </section>
    );
}

export default HomeDetails;
