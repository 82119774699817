import React from 'react'
import ContactUs from '../component/Contact/ContactUs'
import MapSection from '../component/Contact/MapSection'
import ContactSection from '../component/Contact/ContactSection'

const Contact = () => {
  return (
    <>
      <ContactUs />
      <ContactSection />
      <MapSection />
    </>
  )
}
export default Contact