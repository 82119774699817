import React from "react";
import { FiLayout, FiCloud, FiSmartphone, FiUsers, FiSettings, FiCheckCircle } from "react-icons/fi"; 

const Process = () => {
    const services = [
        {
            icon: <FiLayout size={40} />,
            title: "Product Engineering",
            description: "Product Engineering focuses on designing scalable solutions, managing the product lifecycle, developing software, and ensuring quality through rigorous testing and continuous enhancement.",
        },
        {
            icon: <FiCloud size={40} />,
            title: "Cloud",
            description: "Cloud services encompass designing, deploying, and managing secure, scalable infrastructure, with ongoing support and robust security to ensure continuous availability.",
        },
        {
            icon: <FiSmartphone size={40} />,
            title: "Application Development",
            description: "Application Development drives innovation, focusing on designing robust architectures, deploying applications, and refining them through continuous testing and support.",
        },
        {
            icon: <FiUsers size={40} />,
            title: "Staff Augmentation",
            description: "Staff Augmentation provides flexible, scalable staffing solutions, offering skilled professionals to meet project demands and adapt to changing requirements.",
        },
        {
            icon: <FiSettings size={40} />,
            title: "DevOps",
            description: "DevOps integrates development and operations, emphasizing continuous delivery, automation, configuration management, and proactive monitoring to ensure stable performance.",
        },
        {
            icon: <FiCheckCircle size={40} />,
            title: "Code Review and Optimization",
            description: "Code Review and Optimization involve peer reviews, code refactoring, performance enhancements, and automated analysis to maintain high-quality, efficient software development.",
        },
    ];

    return (
        <section className="py-12 bg-[#f4f4f4]">
            <div className="text-center mb-8">
                <h3 className="font-semibold uppercase">Our Focus Area</h3>

                <h2 className="my-2 text-4xl text-[--bg-color] font-bold">What we're offering</h2>

                <div className='flex flex-row justify-center mt-6'>
                    <div className='w-44 border-b-4 border-[--bg-color]'></div>
                </div>
            </div>
            <div className="grid sm:grid-cols-1 lg:grid-cols-3 gap-4 px-4 md:mx-32 my-12">
                {services.map((service, index) => (
                    <div
                        key={index}
                        className="relative bg-white shadow-lg my-4 rounded-lg p-6 max-w-sm mx-auto"
                    >
                        <div className="flex justify-center mb-4">
                            <div className="bg-[--bg-color] text-white p-4   border-[--bg-color] border-[0.5px] rounded-full absolute -top-8">
                                {service.icon}
                            </div>
                        </div>
                        <h3 className="mt-8 text-xl text-center font-semibold text-gray-900 mb-2">{service.title}</h3>
                        <p className="text-gray-600 mb-4 text-center mt-4">{service.description}</p>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Process;
