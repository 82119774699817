import React from 'react'
import H1 from '../assets/c4.jpg';
import { motion } from "framer-motion"
import { FaEye } from "react-icons/fa";
import img1 from '../assets/mission.png';
import img3 from '../assets/targeting.png';
import LeaderShip from '../component/AboutUs/LeaderShip';
import CompanyDiscription from '../component/AboutUs/CompanyDiscription';

const About = () => {
  return (
    <>
      <section className="relative flex flex-col items-center justify-center md:h-[100vh] h-[170vh] bg-white">
        <img
          src={H1}
          alt="Agency Background"
          className="absolute inset-0 object-cover md:h-[70vh] w-full"
        />

        {/* Black Overlay */}
        <div className="absolute inset-0 bg-[#852D2D] opacity-30 md:h-[70vh] h-[100vh]"></div>

        {/* Content */}
        <div className="relative z-10 text-center text-white w-full">
          <motion.h1
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              delay: 0.2,
              x: { type: "spring", stiffness: 60 },
              opacity: { duration: 1 },
              ease: "easeIn",
              duration: 1
            }}
            className="md:text-7xl text-4xl font-bold leading-tight md:mb-8">
            About Us
          </motion.h1>

          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              delay: 0.6,
              y: { type: "spring", stiffness: 60 },
              opacity: { duration: 0.7 },
              ease: "easeIn",
              duration: 1
            }}
            className="flex justify-center items-center md:space-x-4 px-6 py-2 md:mx-64"
          >
            <a href="/" className="py-2 transition text-[16px]">Home</a>
            <span className=" py-2 transition">{`->`}</span>
            <a href="/about" className="py-2 transition text-[16px]">About</a>
          </motion.div>
        </div>

        {/* Features Section */}
        <div className="md:relative md:bottom-0 z-10 md:flex md:justify-center mt-10 md:mx-44 bg-[#f4f4f4]">
          {/* Feature 1 */}
          <div className="flex flex-col items-center bg-white p-4 shadow-lg space-y-3 pb-10 md:w-[25vw]">
            <img alt="card img" className="w-10 h-10" src={img1} />
            <h3 className="text-xl font-semibold mb-2">Our Mission</h3>
            <p className="text-center">
              Our Mission is to keep our customer delighted by offering them excellent services and help them achieving their business goals.
            </p>
          </div>

          {/* Feature 2 */}
          <div className="flex flex-col items-center bg-[--bg-color] p-4 shadow-lg text-white md:space-y-3 md:w-[25vw] pb-10">
            <img alt="card img" src={img3} className="w-10 h-10" />
            <h3 className="text-xl font-semibold mb-2">Our Goals</h3>
            <p className="text-white text-center">
              Our goal is to become Top Services Vendor organization globally.
            </p>
          </div>

          {/* Feature 3 */}
          <div className="flex flex-col items-center bg-black p-4 shadow-lg text-white space-y-3 md:w-[25vw] md:pb-10">
            <FaEye color='white' className="w-10 h-10" />
            <h3 className="text-xl font-semibold mb-2">Our Vision</h3>
            <p className="text-center">
              Our vision within the next few years is to become the technology partner that everyone wants to work with.
            </p>
          </div>
        </div>
      </section>
      <CompanyDiscription/>
      <LeaderShip/>
    </>
  )
}

export default About
