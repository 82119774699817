import React from 'react'
import H1 from '../assets/n3.jpg';
import { motion } from "framer-motion"
import GetQuoteSection from '../component/Portfolio/GetQuoteSection ';
import ProjectProcess from '../component/Portfolio/ProjectProcess';
import ServicesSection from '../component/Portfolio/ServicesSection';

const Portfolio = () => {
    return (
        <>
            <section className="relative flex flex-col items-center justify-center h-[90vh] bg-[#f4f4f4]">
                <img
                    src={H1}
                    alt="Agency Background"
                    className="absolute inset-0 object-cover h-[70vh] w-full"
                />

                {/* Black Overlay */}
                <div className="absolute inset-0 bg-[#852D2D] opacity-30 h-[70vh]"></div>


                {/* Content */}
                <div className="relative z-10 text-center text-white">
                    <motion.h1
                        initial={{ x: 100, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{
                            delay: 0.2,
                            x: { type: "spring", stiffness: 60 },
                            opacity: { duration: 1 },
                            ease: "easeIn",
                            duration: 1
                        }}

                        className="md:text-7xl text-4xl font-bold leading-tight mb-8">
                        Portfolio
                    </motion.h1>

                    <motion.div
                        initial={{ y: 100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.6,
                            y: { type: "spring", stiffness: 60 },
                            opacity: { duration: 0.7 },
                            ease: "easeIn",
                            duration: 1
                        }}

                        className="flex justify-center items-center space-x-4 px-6 py-2 md:mx-64"
                    >
                        <a href="/" className="py-2 transition text-[16px]">Home</a>
                        <span className=" py-2 transition">{`->`}</span>
                        <a href="/about" className="py-2 transition text-[16px]">Portfolio</a>
                    </motion.div>
                </div>
            </section>

            <section className="bg-gray-100 pb-20">
                <div className="container mx-auto text-center">
                    <h2 className="text-gray-600 text-sm font-semibold uppercase mb-2">Our Process</h2>
                    <h2 className="my-2 text-4xl text-[--bg-color] font-bold ">Process We Follow</h2>

                    <div className='flex justify-center '>
                        <div className='w-44 border-b-4 border-[--bg-color]  mb-8'></div>
                    </div>
                    {/* <h1 className="text-3xl font-bold text-gray-800 mb-8">Process We Follow</h1> */}
                    <p className="text-gray-500 max-w-3xl mx-auto mb-12">
                        Our software development process ensures a thorough approach to planning, designing, developing, testing, deploying, and maintaining quality software systems
                    </p>

                    <div className="flex flex-col md:flex-row justify-center items-center gap-8 relative">
                        {/* Connecting line */}
                        <div className="absolute inset-x-0 top-1/2 transform -translate-y-1/2 h-1 bg-gray-300"></div>

                        {/* Step 1 */}
                        <div className="relative bg-white rounded-full p-8 shadow-lg w-64 h-64 flex flex-col items-center justify-center z-10">
                            <div className="absolute top-12 left-44 -translate-y-12 bg-[--bg-color] text-white text-xl font-bold w-12 h-12 rounded-full flex items-center justify-center">
                                01
                            </div>
                            <h3 className="text-xl font-semibold mb-4">Research Project</h3>
                            <p className="text-gray-500 text-sm">
                                We thoroughly understand project requirements and research the best approaches, technologies, and methods aligned with your goals.
                            </p>
                        </div>

                        {/* Step 2 */}
                        <div className="relative bg-white rounded-full p-8 shadow-lg w-64 h-64 flex flex-col items-center justify-center z-10">
                            <div className="absolute top-8 left-36 -translate-y-12 bg-[--bg-color] text-white text-xl font-bold w-12 h-12 rounded-full flex items-center justify-center">
                                02
                            </div>
                            <h3 className="text-xl font-semibold mb-4">Evaluate Plans</h3>
                            <p className="text-gray-500 text-sm">
                                We carefully design and evaluate plans that are robust, scalable, and tailored to your needs, focusing on efficiency and innovation.                            </p>
                        </div>

                        {/* Step 3 */}
                        <div className="relative bg-white rounded-full p-8 shadow-lg w-64 h-64 flex flex-col items-center justify-center z-10">
                            <div className="absolute top-4  -translate-y-12 bg-[--bg-color] text-white text-xl font-bold w-12 h-12 rounded-full flex items-center justify-center">
                                03
                            </div>
                            <h3 className="text-xl font-semibold mb-4">Best Results</h3>
                            <p className="text-gray-500 text-sm">
                                We integrate our research and plans to deliver a product that exceeds expectations in performance, usability, and reliability.                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <ProjectProcess />
            <GetQuoteSection />
            <ServicesSection />
        </>
    )
}

export default Portfolio
