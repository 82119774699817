import React from 'react'
import { motion } from "framer-motion"
import home from '../../assets/TeamLeader.jpeg';

const LeaderShip = () => {
    return (
        <section className='md:px-20 px-10 pt-12 pb-14 bg-[#f4f4f4]'>
            <h2 className="my-2 text-4xl text-[--bg-color] font-bold text-center">Leadership Team </h2>
            <div className='flex justify-center pb-6'>
                <div className='w-64 border-b-4 border-[--bg-color]'></div>
            </div>
            
            <div className='mx-auto md:flex md:flex-wrap md:justify-center gap-8  md:p-4 items-center bg-[#f4f4f4]'>
                <figure
                    class="md:w-[35%] w-full" role="none">
                    <img className='rounded-md' src={home} alt="Jinrai Tech" />
                </figure>

                <div className="py-8 md:w-[50%] w-full">
                    <span>
                        Our Founder Mr Mayank Jain has started this company in year 2023 with a vision to
                        provide industry vide best services to our client, a company who everyone wants to
                        work with. Before starting his own organization, he worked in various Telecom and IT
                        giants and have excelled in various roles such as Director, E2E Delivery Manager, SDM,
                        Partner Engagement Manager, and Technical Project Manager.
                    </span> <br /> <br />

                    <span>
                        He has successfully
                        Spearheaded various IT Delivery, Telecom Installation, Upgrade, Migration, Support
                        & Transformation projects worldwide. While working for so many global clients he
                        has also cultivated strong relationships with clients and stakeholders, achieving and
                        maintained high margins for projects. Now with Jinrai Technologies his focus is to
                        continue that legacy and efficiently manage all the programs to live up to customer
                        expectations.
                    </span> <br /> <br />

                    <span>
                        Talking about our Founder’s personal life. His Father Mr J D Jain and Mother Mrs
                        Vimlesh Jain are also Founder Director of Jinrai Technologies. Our Founder is based
                        out of Noida, his Wife Chetana Jain is dealing into Software Development field and he
                        has two daughters.
                    </span>
                </div>
            </div>
        </section>
    )
}

export default LeaderShip
