import logo from '../assets/logo.jpeg'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react';

const Navbar = () => {
    const [state, setState] = useState('home')

    useEffect(() => {
        if (window.location.pathname == '/') {
            setState("home")
        } else if (window.location.pathname == '/about') {
            setState("about")
        } else if (window.location.pathname == '/portfolio') {
            setState("portfolio")
        } else {
            setState("contact")
        }
    }, [])

    function openNav() {
        document.getElementById("mySidenav").style.width = "250px";
    }

    function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
    }

    return (
        <>
            <nav class="border-gray-200 dark:bg-gray-900 bg-[white] shadow-md shadow-gray-200 ">
                <div class="lg:max-w-[80%] md:max-w-[90%] flex flex-wrap items-center justify-between mx-auto p-4">
                    <Link to="/" onClick={() => setState("home")} class="flex items-center rtl:space-x-reverse">
                        <img src={logo} class="h-12 w-12 rounded-lg" alt="Flowbite Logo" />
                        <span class="self-center text-[16px] font-semibold whitespace-nowrap text-[--bg-color] pt-2" style={{ lineHeight: "18px" }}>Jinrai <br /> Technologies</span>
                    </Link>

                    <button onClick={openNav} type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
                        </svg>
                    </button>

                    <div class="hidden w-full md:block md:w-auto" id="navbar-default">
                        <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                            <li>
                                <Link to="/" onClick={() => setState("home")} class="block py-2 px-3 font-semibold text-[--bg-color] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"> HOME</Link>
                                {state == 'home' && <div className='w-full border-b-2 border-[--bg-color]'></div>}
                            </li>
                            <li>
                                <Link to="/about" onClick={() => setState("about")} class="block py-2 px-3 font-semibold text-[--bg-color] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">ABOUT US</Link>
                                {state == 'about' && <div className='w-full border-b-2 border-[--bg-color]'></div>}
                            </li>
                            <li>
                                <Link to="/portfolio" onClick={() => setState("portfolio")} class="block py-2 px-3 font-semibold text-[--bg-color] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">PORTFOLIO</Link>
                                {state == 'portfolio' && <div className='w-full border-b-2 border-[--bg-color]'></div>}

                            </li>
                            <li>
                                <Link to="/contact" onClick={() => setState("contact")} class="block py-2 px-3 font-semibold text-[--bg-color] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">CONTACT US</Link>
                                {state == 'contact' && <div className='w-full border-b-2 border-[--bg-color]'></div>}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            
            <div id="mySidenav" class="sidenav">
                <a href="javascript:void(0)" class="closebtn" onClick={closeNav}>&times;</a>
                <Link to="/">Home</Link>
                <Link to="/about">About</Link>
                <Link to="/portfolio">Services</Link>
                <Link href="/contact">Contact</Link>
            </div>
        </>
    );
};

export default Navbar;
