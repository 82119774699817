import AOS from 'aos';
import 'aos/dist/aos.css'; 
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import Portfolio from './pages/Portfolio';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  AOS.init({
    duration: 500,
  });
  return (
    <BrowserRouter>
    <Navbar />
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/portfolio' element={<Portfolio/>} />
    </Routes>
    <Footer/>
  </BrowserRouter>
  );
}

export default App;
