import React from 'react'
import c3 from '../assets/n4.jpg'
import c4 from '../assets/n5.jpg'
import c2 from '../assets/n6.jpg'
import Team from '../component/Team';
import Goal from '../component/Goal';
import Process from '../component/Process';
import Marquee from "react-fast-marquee";
import Services from '../component/Services';
import MovingBanner from '../component/Moving';
import HomeDetails from '../component/HomeDetails'
import WhyChooseUs from '../component/WhyChooseUs';

const Home = () => {
  return (
    <>
      <HomeDetails />
      <Goal />
      <Services />
      <section class="flex flex-wrap pt-12">
        <div class="md:w-[33%]">
          <span class="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom">
            <span class="relative block overflow-hidden">
              <img src={c3} class="img-fluid object-cover h-[450px]" alt="Jinrai Leadership" />
              <a href="/about" class="block absolute bottom-0 bg-[rgba(0,0,0,.5)] p-[30px] max-w-[100%] w-[100%]">
                <p class=" text-white text-4xl font-semibold">Leadership</p>
                <p class=" text-white">Meet our expert team <img className='h-8 w-8' src="https://www.lycagroup.com/wp-content/themes/lycatheme/img/_lyca/assets/arrow.svg" alt="Jinrai Leadership" /></p>
              </a>
            </span>
          </span>
        </div>
        <div class="md:w-[33%]">
          <span class="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom">
            <span class="relative block overflow-hidden">
              <img src={c4} class="img-fluid object-cover h-[450px]"lt="Lyca Countries" />
              <a href="/portfolio" class="block absolute bottom-0 bg-[rgba(0,0,0,.5)] p-[30px] max-w-[100%] w-[100%]">
                <p class=" text-white text-4xl font-semibold">Portfolio</p>
                <p class=" text-white">Discover about Company<img className='h-8 w-8' src="https://www.lycagroup.com/wp-content/themes/lycatheme/img/_lyca/assets/arrow.svg" alt="Jinrai Countries" /></p>
              </a>
            </span>
          </span>
        </div>
        <div class="md:w-[33%]">
          <span class="thumb-info thumb-info-no-borders thumb-info-lighten thumb-info-bottom-info thumb-info-bottom-info-dark thumb-info-bottom-info-show-more thumb-info-no-zoom">
            <span class="relative block overflow-hidden">
              <img src={c2} class="img-fluid  object-cover h-[450px]"alt="Jinrai Careers" />
              <a href="/contact" class="block absolute bottom-0 bg-[rgba(0,0,0,.5)] p-[30px] max-w-[100%] w-[100%]">
                <p class=" text-white text-4xl font-semibold">Careers</p>
                <span class=" text-white">Join our worldwide family
                  <img className='h-8 w-8' src="https://www.lycagroup.com/wp-content/themes/lycatheme/img/_lyca/assets/arrow.svg" alt="Jinrai Careers" />
                </span>
              </a>
            </span>
          </span>
        </div>
      </section>

      <section className='md:px-28 px-16  bg-[--bg-color]'>
        <Marquee>
          <MovingBanner title={"Outstanding Outcome"} />
          <MovingBanner title={"Professional Solution"} />
          <MovingBanner title={"Innovative Design"} />
          <MovingBanner title={"Outstanding Outcome"} />
          <MovingBanner title={"Professional Solution"} />
          <MovingBanner title={"Innovative Design"} />
          <MovingBanner title={"Outstanding Outcome"} />
          <MovingBanner title={"Professional Solution"} />
          <MovingBanner title={"Innovative Design"} />
        </Marquee>
      </section>
      <WhyChooseUs />
      <Team />
      <Process />
    </>
  )
}

export default Home
