import React from 'react';
import H1 from '../../assets/c6.jpg';
import { motion } from "framer-motion"

const ContactUs = () => {
  return (
    <section className="relative flex items-center justify-center h-[70vh]">
      <img
        src={H1}
        alt="Agency Background"
        className="absolute inset-0 object-cover h-[70vh] w-full"
      />

      {/* Black Overlay */}
      <div className="absolute inset-0 bg-[#852D2D] opacity-30"></div>

      {/* Content */}
      <div className="relative z-10 text-center text-white w-full">
        <motion.h1
          initial={{ x: 100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 0.2,
            x: { type: "spring", stiffness: 60 },
            opacity: { duration: 1 },
            ease: "easeIn",
            duration: 1
          }}

          className="md:text-7xl text-4xl font-bold leading-tight md:mb-8">
          Contact Us
        </motion.h1>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            delay: 0.6,
            y: { type: "spring", stiffness: 60 },
            opacity: { duration: 0.7 },
            ease: "easeIn",
            duration: 1
          }}

          className="flex justify-center items-center md:space-x-4 px-6 py-2 md:mx-64"
        >
          <a href="/" className="py-2 transition text-[16px]">Home</a>
          <span className=" py-2 transition">{`->`}</span>
          <a href="/about" className="py-2 transition text-[16px]">Contact</a>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactUs;
