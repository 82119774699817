import React from 'react'

const CompanyDiscription = () => {
  return (
    <div className='md:mx-32 mx-12  mb-12'>
      <div className="my-4 py-4">
        <h2 className="my-2 text-4xl text-[--bg-color] font-bold text-center">Company</h2>

        <div className='flex justify-center'>
          <div className='w-32 border-b-4 border-[--bg-color]'></div>
        </div>

        <p className="text-center mt-6 mb-4 md:mx-20">
          Jinrai Technologies is a service and consulting company having an year old legacy supporting large clients across IT and Telecom globally.
        </p>

        <p className="mt-6 mb-4 md:mx-20">
          We have skilled resources and consultants with demonstrated experience.
          Our resources are spreaded across all over India. We take care of Software Development,
          Website Development, Application Development Projects and also support Telecom clients in
          delivering Greenfield Implementation, System Integration, Software Upgrade, Network Migration,
          Transformation Programs and Managed Services. We have extensive hands-on experience in delivering
          quality services for global clients, especially across Europe and Asia regions. We have offices
          presence in Noida through which we have been supporting customers with Implementation, Solutions,
          and Consulting Services.
        </p>
        <p className=" mt-6 mb-4 md:mx-20">
          At Jinrai Technologies, we have been able to deliver solutions and customized Softwares
          that could transform the customer's user experience. It has been possible because of our
          industry practice and strong leadership. At Jinrai Technologies, we continuously strive to
          improve the quality of software solutions and technologies adopted within the IT and Telecom industry.
          Our close partnerships with clients, unique methodology, subject matter expertise, and global experience
          have helped us deliver the most economically sound business value to our customers.
        </p>
      </div>
    </div>
  )
}

export default CompanyDiscription
